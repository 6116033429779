import { useContext, useState } from 'react';
import styled from 'styled-components';
import { ScreensContext } from '../../context/screensContex';

import { Pill } from '../uiElements';
import { pillsTypes } from '../uiElements/pills';
import { v4 as uuidv4 } from 'uuid';

const CategoryTabBar = () => {
	const { setFilterItem } = useContext(ScreensContext);

	const data = [
		{ category: 'All', categoryStatus: '', status: 'active', id: 1 },
		{ category: 'Fintech', categoryStatus: 'Fintech', status: '', id: 2 },

		{ category: 'Logistics', categoryStatus: 'Logistics', status: '', id: 3 },
		{
			category: 'Entertainment',
			categoryStatus: 'Entertainment',
			status: '',
			id: 4,
		},
		{
			category: 'Investment',
			categoryStatus: 'Investment',
			status: '',
			id: 5,
		},
		{
			category: 'Bank',
			categoryStatus: 'Bank',
			status: '',
			id: 6,
		},
		{
			category: 'Crypto',
			categoryStatus: 'Crypto',
			status: '',
			id: 7,
		},
		{
			category: 'Productivity',
			categoryStatus: 'Productivity',
			status: '',
			id: 8,
		},
		{
			category: 'Utilities',
			categoryStatus: 'Utilities',
			status: '',
			id: 9,
		},
		{
			category: 'Education',
			categoryStatus: 'Education',
			status: '',
			id: 10,
		},
		{
			category: 'Lifestyle',
			categoryStatus: 'Lifestyle',
			status: '',
			id: 11,
		},
		{
			category: 'Communication',
			categoryStatus: 'Communication',
			status: '',
			id: 1000,
		},
		{
			category: 'Social',
			categoryStatus: 'Social',
			status: '',
			id: 12,
		},
		{
			category: 'Travel',
			categoryStatus: 'Travel',
			status: '',
			id: 13,
		},
		{
			category: 'Design',
			categoryStatus: 'Design',
			status: '',
			id: 14,
		},
		{
			category: 'Health',
			categoryStatus: 'Health',
			status: '',
			id: 15,
		},
		{
			category: 'Reference',
			categoryStatus: 'Reference',
			status: '',
			id: 16,
		},
		{
			category: 'Sports',
			categoryStatus: 'Sports',
			status: '',
			id: 17,
		},
		{ category: 'Finance', categoryStatus: 'Finance', status: '', id: 3 },
	];
	const [d, setd] = useState(data)


	const onClickPill = (e,result) => {
		const noActiveD = d.map((x) => ({
			...x,
			status:""
		}))
		const setActive = noActiveD.map((x) => {
			if (x.id === result.id) {
				x.status ="active"
				return x
			} 
			return x
		})
		setd(setActive)
		setFilterItem(result.categoryStatus);
	};

	return (
		<CategoryTabContainer>
			<CategoryTabWrapper>
				{
					<>
						{d.map((result) => {
							return (
								<Pill key={uuidv4()} type={pillsTypes.category}>
									<button
										className={`pills ${result.status}`}
										onClick={(e) => onClickPill(e, result)}
										name={result.categoryStatus}
									>
										{result.category}
									</button>
								</Pill>
							);
						})}
					</>
				}
			</CategoryTabWrapper>
		</CategoryTabContainer>
	);
};

const CategoryTabContainer = styled.section`
	margin: 1.5em 0;
	padding: 1em 0;
	border: 1px solid var(--light-grey-color);
	position: sticky;
	top: 80px;
	background: white;
	z-index: 98;
	@media (min-width: 768px) {
		margin: 3em 0;
	}
`;

const CategoryTabWrapper = styled.div`
	display: flex;
	flex-wrap: nowrap;
	overflow-x: scroll;
	gap: 0.8em;

	::-webkit-scrollbar {
		display: none;
	}
`;

export default CategoryTabBar;
