import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useRouter } from 'next/router';
import { Button } from '../uiElements';
import { buttonTypes } from '../uiElements/button';
import { signInWithGoogle } from '../../supabase';
const Login = ({ toggleModal }) => {
	const router = useRouter();

	//handler function for signin with Google
	const handleSignupWithGoogle = async () => {
		try {
			await signInWithGoogle(router.asPath);
			toggleModal();
		} catch (e) {
			toast.error('Sorry an error occurred while trying to login');
		}
	};

	return (
		<LoginContainer onClick={(e) => e.stopPropagation()}>
			<img
				src='/assets/img/cancel.svg'
				onClick={() => {
					toggleModal();
				}}
				alt='cancel button'
			/>
			<h1>
				<span>UL</span>
				Sign in for Free
			</h1>
			<p>Welcome to UiLand</p>
			<div onClick={() => handleSignupWithGoogle()}>
				<Button type={buttonTypes.google}>
					<div className='flex-row'>
						<div className='google-logo'>
							<div className='h-30'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									viewBox='0 0 48 48'
									width='100%'
									height='100%'
								>
									<path
										fill='#FFC107'
										d='M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z'
									></path>
									<path
										fill='#FF3D00'
										d='M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z'
									></path>
									<path
										fill='#4CAF50'
										d='M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z'
									></path>
									<path
										fill='#1976D2'
										d='M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z'
									></path>
								</svg>
							</div>
						</div>
						<div>Continue with Google</div>
					</div>
				</Button>
			</div>
		</LoginContainer>
	);
};

const LoginContainer = styled.div`
	background-color: #fff;
	border-radius: 20px;
	width: 80%;
	max-width: 650px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 32px 0;
	box-shadow: 1px 1px 10px rgba(255, 255, 255, 0.4);
	position: relative;
	svg {
		max-width: 50px;
	}
	img {
		width: 5rem;
		transform-origin: 100% 0;
		opacity: 1;
		position: absolute;
		top: 1rem;
		right: 1rem;
		transform: scale(0.28);
	}
	h1 {
		font-weight: 700;
		font-size: 24px;
		text-align: center;
		color: #202021;

		span {
			display: block;
			margin-bottom: 0.5em;
			color: var(--primary-color);
		}
		@media (min-width: 768px) {
			font-size: 48px;
		}
	}

	p {
		font-weight: 400;
		font-size: 16px;
		color: #626569;
		margin: 1.5em;
		@media (min-width: 768px) {
			font-size: 24px;
		}
	}

	.google-logo {
		margin-right: 0.8em;
		font-weight: 700;
		height: 30px;
	}
`;

export default Login;
