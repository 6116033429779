import CategoryTabBar from '../CategoryTab';
import ScreensInCategory from '../ScreensInCategory';
import SearchTabBar from '../SearchTabBar';
import Tab from '../TabSection';

const ScreensTab = ({ screens }) => {
	return (
		<>
			{/* <SearchTabBar /> */}
			{/* <Tab/> */}
			<CategoryTabBar />
			<ScreensInCategory screens={screens} />
		</>
	);
};

export default ScreensTab;
